@import '~antd/es/style/themes/default.less';

.container-header-dropdown > * {
  background-color: @popover-bg;
  border-radius: 4px;
  box-shadow: @shadow-1-down;
}

@media screen and (max-width: @screen-xs) {
  .container-header-dropdown {
    width: 100% !important;
  }
  .container-header-dropdown > * {
    border-radius: 0 !important;
  }
}
@primary-color: #1777d1;